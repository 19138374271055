#layout {
  padding-top: 4rem;
}

#wrapper {
  margin: 2rem 0 0 0;
  .responsive(800px, {
    margin: 1.6rem 0 0 0;
  });
}

#main {
  margin: 0 2rem;
  .responsive(1500px, {
    margin: 0 1rem;
  });
}

.filters-modal {
  max-width: 300px;
  width: calc(100vw - 2rem);

  .ant-modal {
    &-close-x {
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-body {
      padding-top: 48px;
    }
  }
}

.ant-btn--wrapper {
  & .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

#table-wrapper {
  display: flex;
  align-items: flex-start;

  .responsive(1020px, {
    flex-direction: column;
  });

  & > .ant-filter-btn {
    // height: 24px;
    // width: 24px;
    // transform: scale(2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px 15px 0;

    .responsive(767px, {
      margin: 0 0 15px 0;
    });

    & > span {
      line-height: 0;
    }
  }
}

#table-sidebar {
  width: 280px;
  margin-right: 1.6rem;

  .responsive(1300px, {
    width: auto;
    max-width: 320px;
    margin-right: 0;

  });

  .responsive(600px, {
    margin-right: 0;
  });
  .responsive(360px, {
    align-self: center;
  });

  .ant-picker {
    flex-grow: 1;
    margin-left: 1rem;
    max-width: 180px;
  }
}

.ant-table-wrapper {
  flex-grow: 1;
}

.ant-layout-sider-children {
  margin-top: unset;
  padding-top: unset;
}

.ant-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.5);
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td,
.ant-table-column-sorters {
  .responsive(1600px, {
    padding: 0.8rem
  });

  .responsive(768px, {
    padding: 0.5rem
  });
}

.date-status {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  font-size: 0.8rem !important;
  top: 75%;
}

.form-comment-editor {
  resize: none;
}


//popup
.popup {
  position: fixed;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .1);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;


  &.open {
    opacity: 1;
    visibility: visible;
    transition: 0.3s;
  }


  &-body-1 {
    border-radius: 0.5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 40rem;
    width: 100%;
    transform: translate(-50%, -50%);
    padding: 3.12rem;
    scrollbar-color: transparent transparent;

  }

  &-body-2 {
    border-radius: 0.5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 80rem;
    width: 100%;
    transform: translate(-50%, -50%);
    padding: 3.12rem;
    scrollbar-color: transparent transparent;
  }

  textarea {
    resize: none;
    flex: 1 0 auto;
    padding: 1rem;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background: white;
  height: 400px;
  width: 100%;
  overflow-y:scroll ;
}

.modal-item {
  display: flex;
  gap: 2rem;
}

.popup-btn {
  width: 100%;
  flex: 0 0 auto;
  cursor: pointer;
}

.date-span {
  width: 7rem;
  text-align: center;
}
.status-span {
  width: 8rem;
  text-align: center;
}

.modal-item-header {
  border-bottom: 1px solid black;
  text-align: center;
}

.comment-elem {
  flex-grow: 1;
  text-align: left;
}

.btn-history {
  background: #bfc1dd;
  cursor: pointer;
}

@import 'header.less';
