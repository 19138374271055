#header {
	background-color: @white;
	height: 4rem;
	line-height: 4rem;
	padding: 0 2rem;
	box-shadow: 0 2px 20px rgba(0, 0, 0, 0.05);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 4;

	.responsive(1500px, {
		padding: 0 1rem;
	});

	#logo {
		margin-right: 2rem;
		.responsive(768px, {
			margin-right: 0;
		});
	}
	.ant-menu {
		border: none;
	}
	& > .ant-row-middle {
		height: 100%;
	}
	.ant-menu-item {
		z-index: 2;
		border: none !important;
		font-weight: 700;
		letter-spacing: 0.05rem;
		margin: 0 1rem;
		top: 0;

		.responsive(400px, {
			margin: 0 0 0 0.7rem;
		});
		.responsive(355px, {
			font-size: 13px;
			font-weight: 600;
			
		});

		a {
			color: @black;
			position: relative;
			display: block;
			height: 100%;
			width: 100%;
			transition: 0.3s ease;
			&::before {
				position: absolute;
				content: '';
				height: 2px;
				width: 0;
				top: unset;
				bottom: 0;
				left: 50%;
				right: unset;
				transform: translateX(-50%);
				background: @red;
				border-radius: 2px;
				// transition: 0.3s;
			}
			&:hover,
			&:focus,
			&.active {
				color: @red;
			}
			&.active {
				&:before {
					width: 100%;
				}
			}
		}
	}

	.ant-btn {
		.responsive(450px, {
			font-size: 13px;
			padding: 0.25rem 0.5rem;
		});
	}
}
