@font-face {
	font-family: Roboto;
	font-weight: 400;
	src: url('../fonts/Roboto-Medium.ttf') format('truetype'), url(../fonts/Roboto-Medium.woff2) format('woff2');
}
@font-face {
	font-family: Roboto;
	font-weight: 300;
	src: url('../fonts/Roboto-Light.ttf') format('truetype'), url(../fonts/Roboto-Light.woff2) format('woff2');
}
@font-face {
	font-family: Roboto;
	font-weight: 700;
	src: url('../fonts/Roboto-Bold.ttf') format('truetype'), url(../fonts/Roboto-Bold.woff2) format('woff2');
}
@font-face {
	font-family: Roboto;
	font-weight: 900;
	src: url('../fonts/Roboto-Black.ttf') format('truetype'), url(../fonts/Roboto-Black.woff2) format('woff2');
}
@import '~antd/dist/antd.less';
@import './_vars.less';
@import './_mixins.less';

html,
body {
	font-size: 15px;
	font-family: Roboto;
}
textarea {
	white-space: pre-wrap;
}

#layout {
	min-height: 100vh;
}

* {
	scrollbar-color: #585858 #fff;
	scrollbar-width: thin;
}
* ::-webkit-scrollbar {
	width: 6px;
}
*::-webkit-scrollbar-track {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: #fff;
}
* ::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: #585858;
}

.ant-select {
	* {
		cursor: pointer !important;
	}
	&-selection-search-input:focus {
		box-shadow: none;
	}
}
.btn-center {
	justify-content: center;

	.ant-form-item-control-input-content {
		display: flex;
		justify-content: center;
	}
}

.antd-table-empty {
	flex-grow: 1;
	text-align: center;
}
.ml-auto {
	margin-left: auto;
}
.ant-card-body {
	padding: 1rem;
}

@import 'layout';
@import 'ui';

@font-size-base: 15px;