.ant-table-tbody {
	* {
		font-size: 1rem;
		line-height: 1.25rem;
	}
	td {
		padding: 1rem;
	}
	.ant-input {
		border-color: transparent;

		&:hover,
		&:focus {
			border-color: @gray;
		}
	}

	.btn-edit
	// .btn-delete
	{
		margin-right: 0.5rem;
	}

	.ant-select {
		width: 100%;
	}

	.table-link {
		display: flex;
		line-height: 2rem;
		// justify-content: center;
		align-items: center;
		&:hover,
		&:focus {
			color: @red;
		}
	}

	*:focus {
		box-shadow: @shadow-focus;
	}
	.btn-edit:focus,
	.btn-edit:hover {
		border-color: rgba(green, 0.3);
	}
	.btn-edit:focus {
		box-shadow: @shadow-focus-edit;
	}
	.btn-delete:focus,
	.btn-delete:hover {
		border-color: rgba(red, 0.3);
	}
	.btn-delete:focus {
		box-shadow: @shadow-focus-delete;
	}

	.ant-select {
		&-selector {
			border-color: transparent !important;
			transition: none;
		}
		&-arrow {
			opacity: 0 !important;
			right: 9px !important;
		}
		&:hover,
		&-focused {
			.ant-select-selector {
				border-color: #d9d9d9 !important;
			}

			.ant-select-arrow {
				opacity: 1 !important;
			}
		}
	}
}

.ant-select {
	width: 100%;

	&-selection-search {
		position: relative;
		z-index: 10;
	}
	&.ant-pagination-options-size-changer {
		width: 120px;
		.ant-select-selection-item {
			text-align: center;
		}
	}
	&-selector {
		padding: 0 !important;
	}

	.ant-tag {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		padding: 0 1rem;
		margin-right: 0.8rem;
		font-size: 1rem;
	}
	input {
		box-shadow: none !important;
	}
	.ant-menu-submenu-arrow {
		right: 2px;
		top: 2px;
		transform: rotate(90deg);
		transform-origin: center;
		transition: unset;
	}
	&-open .ant-menu-submenu-arrow {
		transform: rotate(90deg) scaleX(-1);
		transform-origin: center;
		top: 10px;
	}
}
.ant-select-item-option-content .ant-tag {
	width: 100%;
	height: 30px;
	margin: 0.3rem 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	border-radius: 3px;
}
.form-link-editor {
	display: flex;
	align-items: center;

	a {
		margin-left: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 31px;
		width: 31px;
		border: 1px solid @gray;
		border-radius: 0.4rem;
		&:hover,
		&:active,
		&:focus {
			border-color: #40a9ff;
		}
		&:active,
		&:focus {
			box-shadow: @shadow-focus;
		}
	}
}

#table-sidebar {
	.ant-btn {
		width: 100%;
		margin-top: 10px;
	}
	.ant-form-item-control:last-of-type button {
		margin-top: 0;
	}
	h3 {
		font-size: 16px;
		line-height: 18px;
		text-align: center;
		margin-bottom: 1rem;
		text-transform: uppercase;
	}
	.name-filter-input {
		margin-top: 1.2rem;
	}
	.ant-form-item {
		margin-bottom: 1rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.ant-divider-horizontal {
		margin: 0.5rem 0;
	}
	.wrapper {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1.2rem;
		align-items: center;
		padding-left: 0.6rem;
	}
}
.ant-modal {
	&-body {
		padding: 12px 24px;
	}
	&-footer {
		padding-right: 24px;
	}
}

#table-form {
	&.documents-form {
		display: flex;
		flex-wrap: wrap;

		.responsive(600px, {
			flex-wrap: nowrap;
			flex-direction: column;
		});

		.ant-form-item {
			width: calc(50% - 2rem);

			// &:nth-child(3),
			// &:nth-child(5) {
			// 	width: calc(100% / 3 - 3rem);
			// }
			// &:nth-child(4) {
			// 	width: calc(100% / 3);
			// }

			&:last-child {
				margin: 2rem 1rem 0 auto;
				width: calc(75% - 2rem);
			}

			.responsive(600px, {
				width: 100% !important;
			});
		}
	}

	.ant-form-item {
		position: relative;
		margin: 0.75rem 1rem;

		.error-form-control {
			position: absolute;
			color: red;
			bottom: 0;
			left: 0;
			transform: translate(0, 100%);
		}

		.error-field {
			box-shadow: 0 0 0 1px red;
			border-color: red;
			border-radius: 2px;
			&.ant-select {
				box-shadow: 0 0 0 2px red;
			}
		}
	}
}
.ant-form-item-control-input-content {
	justify-content: flex-end;
	.ant-btn {
		width: calc(50% - 5px);
		margin-right: 5px;

		& + .ant-btn {
			margin-right: 0;
			margin-left: 5px;
		}
	}
}
